import React from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { useNavigate } from "react-router-dom";

export default function ErrorScreen({ setShowError,getEventDetails,error }) {
  const navigate=useNavigate()
  const handleClickTryAgain=()=>{
    getEventDetails()
    setShowError((prev)=>({status:false,massage:""}))
  }
  return (
    <>
      <div
        style={{
          height: "55vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="bg-lite"
      >
        <div className="text-center">
          {/* <h1 className="fw-bold text-grey"> <ErrorIcon fontSize="large"/></h1> */}
          <h2 className="fw-bold text-grey">Oops! </h2>
          <h4 className="fw-bold text-grey">
            {error?error+" Please try again later.":"Something went wrong!.Please try again later"}
            {/* <pre style={{ color: "red" }}>{}</pre> */}
          </h4>
          <div>
          <button onClick={handleClickTryAgain} className="btn  mt-3 btn-primary mx-4 btn-custom-width">
            {" "}
            Try Again 
          </button>
          <button onClick={()=>navigate("/")} className="btn mt-3 btn-secondary btn-custom-width">
            {" "}
            Go to Home
          </button>
          </div>
        </div>
      </div>
    </>
  );
}
