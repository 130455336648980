import React, { useEffect, useState } from "react";

export default function CreateEventLoader() {
  return (
    <div className="container">
      <h2 className="m-0 text-center fw-bold py-4 text-primary">CREATE AN EVENT</h2>
      <div className="row">
        <div className="col-md-10 m-auto">
          <div
            className="fancy-card bg-lite pb-5 mb-5 position-relative"
            style={{ minHeight: "600px" }}
          >
            <div className="row m-0" style={{ borderRadius: "15px 15px 0px 0px" }}>
              <div className="col-12 p-0">
                <div className="card-loader m-0 loading h-auto shadow-none">
                  <div className="row">
                    <div className="">
                      <div style={{ height: "30vh" }} className="image-2 w-100"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-10 m-auto">
                <div className="card-loader m-0 loading h-auto shadow-none">
                  <div className="row mb-3 mt-5">
                    <div className="col-md-2">
                      <div className="title w-100"></div>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-md-3">
                      <div className="title w-100"></div>
                    </div>
                    <div className="col-md-3">
                      <div className="title w-100"></div>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-md-3">
                      <div className="title w-100"></div>
                    </div>
                    <div className="col-md-3">
                      <div className="title w-100"></div>
                    </div>
                  </div>
                  <div className="row mb-3 mt-5">
                    <div className="col-md-2">
                      <div className="title w-100"></div>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-md-3">
                      <div className="title w-100"></div>
                    </div>
                    <div className="col-md-3">
                      <div className="title w-100"></div>
                    </div>
                    <div className="col-md-3">
                      <div className="title w-100"></div>
                    </div>
                    <div className="col-md-3">
                      <div className="title w-100"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 p-0">
                <div className="card-loader m-0 loading h-auto shadow-none">
                  <div className="row mt-5">
                    <div className="col-md-6 px-0">
                      <div className="title py-4 w-100"></div>
                    </div>
                    <div className="col-md-6 px-0">
                      <div className="title py-4 w-100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
