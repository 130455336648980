import React, { useEffect, useState } from "react";

export default function BuyLoader() {
  return (
    <div className="container pt-3 pt-lg-5">
    <div className="row">
      <div className="col-12">
        <div className="card-loader loading h-auto shadow-none mb-5">
          <div className="row">
            <div className="">
            <div style={{height:"60vh"}} className="image-2 w-100"></div>
            </div>
          </div>
          <hr />
          <div className="card-loader loading h-auto shadow-none">
            <div className="title m-3"></div>
            <div className="title m-3"></div>
            <div className="title m-3"></div>
            <div className="title m-3"></div>
            <div className="title m-3 w-25 float-start"></div>
          </div>
          <div className="card-loader loading h-auto shadow-none">
            <div className="title m-3"></div>
            <div className="title m-3"></div>
            <div className="title m-3"></div>
            <div className="title w-25 float-end m-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
