import React, { useEffect, useState } from "react";

export default function BuyerDashboardLoader() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card-loader loading h-auto my-3">
            <div className="d-flex align-items-center w-50">
              <div className="w19 m-3">
                <h4 className="title my-2"> </h4>
              </div>
              <div className="w19 m-3">
                <h4 className="title my-1"> </h4>
              </div>
              <div className="w19 m-3">
                <h4 className="title my-1"> </h4>
              </div>
            </div>
            <div className=" d-flex justify-content-end w-50">
              <div className="w-25 m-3">
                <h4 className="title my-2"> </h4>
              </div>
              <div className="w-25 m-3">
                <h4 className="title my-2"> </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 col-xl-4">
          <div className="card-loader loading h-auto">
            <div className="d-flex">
              <div className="w-50 image m-3"></div>
              <div className="w-50 m-3">
                <h4 className="title my-3"> </h4>
                <h4 className="title my-3"> </h4>
                <h4 className="title w-75"> </h4>
              </div>
            </div>
          </div>
          <div className="card-loader loading h-auto">
            <div className="content p-3">
              <h4 className="title"> </h4>
              <div className="description my-2"></div>
              <div className="description my-2"></div>
              <div className="description my-2"></div>
              <div className="description"></div>
            </div>
          </div>
          <div className="card-loader loading h-auto">
            <div className="content p-3">
              <h4 className="title"> </h4>
              <div className="description my-2"></div>
              <div className="description my-2"></div>
              <div className="description my-2"></div>
              {/* <div className="description"></div> */}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8 col-xl-8">
          <div className="card-loader loading h-auto">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="title m-3"> </h4>
                <h4 className="title w-25 m-3"> </h4>
              </div>
              <div className="col-lg-7">
                <div className="w-100 m-3">
                  <h4 className="title my-3"> </h4>
                  <h4 className="title my-3"> </h4>
                  <h4 className="title w-50"> </h4>
                  <h4 className="title mt-5 mb-3"> </h4>
                  <h4 className="title my-3"> </h4>
                  <h4 className="title my-5"> </h4>
                  <h4 className="title my-3"> </h4>
                  <h4 className="title my-3"> </h4>
                  <h4 className="title my-5"> </h4>
                  <h4 className="title w-25 my-2"> </h4>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="image-2 m-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
